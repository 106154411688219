import { BrowserModule } from '@angular/platform-browser';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular-ivy';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GuardsModule } from '@flink-legacy/shared/guards/guards.module';
import { ServicesModule } from '@flink-legacy/shared/services/services.module';
import { CoreModule } from '@flink-legacy/core/core.module';
import { TokenInterceptor } from '@flink-legacy/shared/token.interceptor';
import { SharedModule } from '@flink-legacy/shared/shared.module';
import { IhzIconsModule } from '@bling-fe/shared/icons/ihz-icons.module';
import { AnalyticsService, CookiebotService } from '@bling-fe/shared/services';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot({
      mode: 'ios',
      swipeBackEnabled: false,
    }),
    AppRoutingModule,
    GuardsModule,
    ServicesModule,
    CoreModule,
    SharedModule,
    IhzIconsModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private translate: TranslateService,
    private analyticsService: AnalyticsService,
    private cookiebotService: CookiebotService
  ) {
    this.translate.setDefaultLang('de');
    this.translate.use('de');

    this.cookiebotService.consents$.subscribe(consent => {
      if (consent.statistics) {
        this.analyticsService.init();
        this.analyticsService.enable();
      } else {
        this.analyticsService.disable();
      }
    });
  }
}
