import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

type CookiebotConsents = {
  marketing: boolean;
  statistics: boolean;
  preferences: boolean;
  necessary: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class CookiebotService {
  private window: any = window;
  consents$ = new Subject<CookiebotConsents>();

  constructor() {
    this.setupConsentHandlers();
  }

  show() {
    document.body.classList.add('cookiebot-overlay');
    this.window.Cookiebot?.show();
  }

  hide() {
    document.body.classList.remove('cookiebot-overlay');

    if (
      this.window.Cookiebot &&
      document.getElementById('CybotCookiebotDialog')
    ) {
      this.window.Cookiebot.hide();
    }
  }

  private setupConsentHandlers() {
    ['CookiebotOnAccept', 'CookiebotOnDecline'].forEach(event => {
      window.addEventListener(event, () => {
        this.hide();

        this.consents$.next({
          marketing: this.marketingConsent,
          statistics: this.statisticsConsent,
          preferences: this.preferencesConsent,
          necessary: this.necessaryConsent,
        });
      });
    });
  }

  get marketingConsent() {
    return this.window.Cookiebot?.consent?.marketing;
  }
  get statisticsConsent() {
    return this.window.Cookiebot?.consent?.statistics;
  }
  get preferencesConsent() {
    return this.window.Cookiebot?.consent?.preferences;
  }
  get necessaryConsent() {
    return this.window.Cookiebot?.consent?.necessary;
  }
}
